export default {
  type: "flex",
  altText: "訂單",
  contents: {
    type: "bubble",
    body: {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: "訂單",
          weight: "bold",
          size: "xl",
          align: "center",
          contents: [],
        },
        {
          type: "separator",
          margin: "md",
        },
        {
          type: "text",
          text: "單號：20210911000003",
          margin: "md",
          color: "#666666",
          size: "sm",
        },
        {
          type: "text",
          text: "日期：2021-09-11",
          color: "#666666",
          size: "sm",
        },
        {
          type: "separator",
          margin: "md",
        }
      ],
    },
    footer: {
      type: "box",
      layout: "vertical",
      contents: [],
    },
  },
};
