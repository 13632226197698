<template>
  <b-container>
    <div class="d-flex justify-content-center m-3">
      <h2>訂單</h2>
    </div>
    <b-form class="m-3">
      <b-card no-body class="mb-3" v-for="item in productlist" :key="item.id">
        <b-card-body class="p-2">
          <b-row>
            <b-col class="d-flex flex-column">
              <div class="">{{ item.text }} <b-badge @click="showDetail(item.id)" v-if="item.img1 || item.img2 || item.desc">詳細</b-badge></div>
              <div class="text-muted">
                <small>價格：{{ item.price }}</small>
              </div>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end align-items-center">
              <b-form-input type="number" size="sm" placeholder="數量" v-model="item.quantity"></b-form-input>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col cols="8" class="text-muted d-flex justify-content-end">合計：</b-col>
        <b-col cols="4" class="text-muted">{{ subtotal }}</b-col>
        <b-col cols="8" class="text-muted d-flex justify-content-end">運費：</b-col>
        <b-col cols="4" class="text-muted">{{ order.delivery_fee }}</b-col>
        <b-col cols="8" class="text-muted d-flex justify-content-end">總計：</b-col>
        <b-col cols="4" class="text-muted">{{ total }}</b-col>
      </b-row>
      <hr class="my-3" />
      <b-form-checkbox @change="useUserinfo">使用註冊資料</b-form-checkbox>
      <b-form-group label="收件人">
        <b-form-input type="text" v-model="order.receiver" placeholder="請輸入姓名" :disabled="useDefaultInfo"></b-form-input>
      </b-form-group>

      <b-form-group label="收件地址">
        <b-row class="mb-2">
          <b-col class="pr-0">
            <b-form-select v-model="order.county" :options="countylist" @change="setArea()" :disabled="useDefaultInfo"></b-form-select>
          </b-col>
          <b-col>
            <b-form-select v-model="order.area" :options="arealist" :disabled="useDefaultInfo"></b-form-select>
          </b-col>
        </b-row>
        <b-form-input type="text" v-model="order.address" placeholder="請輸入地址" :disabled="useDefaultInfo"></b-form-input>
      </b-form-group>
      <b-form-group label="電話">
        <b-form-input type="text" v-model="order.phone" placeholder="請輸入聯絡電話" :disabled="useDefaultInfo"></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-center mt-4">
        <b-button variant="primary" @click="submit" :disabled="subtotal === 0">送出</b-button>
      </div>
    </b-form>
    <b-modal v-model="model.show" hide-header centered no-close-on-backdrop no-close-on-esc>
      <div class="w-100 d-flex justify-content-center">{{ model.text }}</div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button variant="secondary" size="sm" @click="close"> 確認 </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="detail.show" hide-footer centered :title="detail.title">
      <div class="w-100 d-flex justify-content-center mb-2" v-if="detail.img1">
        <b-img :src="detail.img1" fluid></b-img>
      </div>
      <div class="w-100 d-flex justify-content-center mb-2" v-if="detail.img2">
        <b-img :src="detail.img2" fluid></b-img>
      </div>
      <div class="w-100 d-flex" v-html="detail.desc"></div>
    </b-modal>

    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" rounded="sm" no-wrap></b-overlay>
  </b-container>
</template>

<script>
import liff from "@line/liff";
import ziplist from "../commons/zip";
import orderConfirmMessage from "../templates/orderConfirmMessage";
export default {
  data() {
    return {
      loading: true,
      model: {
        show: false,
        text: "",
      },
      productlist: [],
      quotationlist: [],
      countylist: [{ value: "", text: "請選擇" }],
      arealist: [{ value: "", text: "請選擇" }],
      orderNumber: "",
      order: {
        receiver: "",
        county: "",
        area: "",
        address: "",
        phone: "",
        delivery_fee: 0,
      },
      detail: {
        show: false,
        img1: "",
        img2: "",
        desc: "",
        title: "",
      },
      userinfo: null,
      useDefaultInfo: false,
      liffId: "",
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    subtotal() {
      let result = 0;
      this.productlist.forEach((item) => {
        if (item.quantity) result += item.quantity * item.price;
      });
      return result;
    },
    total() {
      return this.order.delivery_fee + this.subtotal;
    },
  },
  methods: {
    async init() {
      document.title = "送出訂單";
      await this.getConfig();
      await liff.init({ liffId: this.liffId });
      await liff.getProfile().then((profile) => {
        this.lineId = profile.userId;
      });
      await this.checkRegister();
      await this.getQuotation();
      await this.getProductlist();
      this.countylist = [{ value: "", text: "請選擇" }, ...ziplist.map((p) => ({ value: p.countyName, text: p.countyName }))];
      this.loading = false;
    },
    async getConfig() {
      await this.$http.get("/configuration").then((res) => {
        this.liffId = res.data.liff_order;
        this.order.delivery_fee = res.data.delivery_fee;
      });
    },
    setArea() {
      if (this.order.county) {
        let zipArealist = ziplist.find((p) => p.countyName === this.order.county);
        this.arealist = [{ value: "", text: "請選擇" }, ...zipArealist.area.map((p) => ({ value: p.name, text: p.name }))];
      } else {
        this.arealist = [{ value: "", text: "請選擇" }];
      }
      this.order.area = "";
    },
    useUserinfo(select) {
      this.useDefaultInfo = select;
      if (select) {
        this.order.receiver = this.userinfo.name;
        this.order.county = this.userinfo.county;
        this.setArea();
        this.order.area = this.userinfo.area;
        this.order.address = this.userinfo.address;
        this.order.phone = this.userinfo.phone;
      }
    },
    async getProductlist() {
      await this.$http.get("/product/list").then((res) => {
        if (res.data.success) {
          let quotationIds = this.quotationlist.map((p) => p.product_id);
          this.productlist = res.data.content
            .filter((item) => quotationIds.includes(item.id))
            .map((item) => ({
              id: item.id,
              text: item.no + " " + item.name,
              price: this.quotationlist.find((p) => p.product_id == item.id).price,
              quantity: null,
              img1: item.img1,
              img2: item.img2,
              desc: item.description,
            }));
        }
      });
    },
    async getQuotation() {
      await this.$http.get("/quotation/list", { params: { lineId: this.lineId } }).then((res) => {
        if (res.data.success) {
          this.quotationlist = res.data.content.filter((item) => item.price != null);
        }
      });
    },
    submit() {
      this.loading = true;
      let errorMessages = [];
      if (this.order.receiver.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("收件人不得為空");
      }
      if (this.order.county.replace(/(^s*)|(s*$)/g, "").length === 0 || this.order.area.replace(/(^s*)|(s*$)/g, "").length === 0 || this.order.address.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("地址不得為空");
      }
      if (this.order.phone.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("電話不得為空");
      }
      if (errorMessages.length > 0) {
        let h = this.$createElement;
        let messageVNode = h("div", { domProps: { innerHTML: errorMessages.join("<br />") } });
        this.$bvModal.msgBoxOk([messageVNode], {
          centered: true,
          buttonSize: "sm",
          okVariant: "danger",
          footerClass: "w-100 d-flex justify-content-center",
          okTitle: "返回",
        });
        this.loading = false;
        return;
      }
      let params = {
        lineId: this.lineId,
        receiver: this.order.receiver,
        address: this.order.county + this.order.area + this.order.address,
        phone: this.order.phone,
        delivery_fee: this.order.delivery_fee,
        total: this.total,
        details: this.productlist
          .filter((p) => p.quantity > 0)
          .map((p) => ({
            product_id: p.id,
            price: p.price,
            quantity: p.quantity,
          })),
      };
      this.$http.post("/order/create", params).then((res) => {
        if (res.data.success) {
          this.model.text = "訂單送出成功";
          this.model.show = true;
          this.orderNumber = res.data.content;
          this.sendConfirm();
        }
        this.loading = false;
      });
    },
    async checkRegister() {
      await this.$http.get("/user/confirm", { params: { id: this.lineId } }).then((res) => {
        if (res.data.success) {
          if (!res.data.content) {
            this.model.text = "請先進行註冊";
            this.model.show = true;
          } else {
            this.getUserinfo();
          }
        }
      });
    },
    getUserinfo() {
      this.$http.get("/user/view", { params: { id: this.lineId } }).then((res) => {
        if (res.data.success) {
          this.userinfo = res.data.content;
        }
      });
    },
    close() {
      liff.closeWindow();
    },
    sendConfirm() {
      let message = orderConfirmMessage;
      message.contents.body.contents[2].text = "單號：" + this.orderNumber;
      var today = new Date();
      message.contents.body.contents[3].text = "日期：" + today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      this.productlist
        .filter((p) => p.quantity > 0)
        .map((p) => ({
          type: "box",
          layout: "vertical",
          contents: [
            {
              type: "text",
              text: p.text,
              weight: "bold",
              wrap: true,
            },
            {
              type: "text",
              text: "價格：$" + p.price,
              size: "sm",
              color: "#888888",
            },
            {
              type: "text",
              text: "數量：" + p.quantity,
              size: "sm",
              color: "#888888",
            },
          ],
          margin: "lg",
        }))
        .forEach((item) => {
          message.contents.body.contents.push(item);
        }),
        liff.sendMessages([message]);
    },
    showDetail(value) {
      let data = this.productlist.find((p) => p.id === value);
      this.detail.title = data.text;
      this.detail.img1 = data.img1;
      this.detail.img2 = data.img2;
      this.detail.desc = data.desc;
      this.detail.show = true;
    },
  },
};
</script>